import { useContext, useState } from 'react';
import { Modal, Collapse } from 'antd';
import { useIntl } from 'react-intl';
import { ContextWrapper } from 'contexts/wrapper.context';
import { updateFeatureConfiguration } from 'services/auth';

import { PROHIBITED_ITEMS_MODAL_COLLAPSE_TITLE } from 'constants/home-page-activation';

import BRButton from 'components/BRButton/BRButton';
import { BannerContent } from 'components/Banner/Banner';

import { ReactComponent as UpArrow } from 'assets/bosta-icons/Chevron-up.svg';
import { ReactComponent as DownArrow } from 'assets/bosta-icons/Chevron-down.svg';
import { ReactComponent as TooltipIcon } from 'assets/bosta-icons/Tooltip.svg';

import './ProhibitedItemsAndUncoveredZones.less';

const ProhibitedItemsAndUncoveredZones = ({ close, ...props }) => {
  const [activeKey, setActiveKey] = useState('0');

  const intl = useIntl();

  const handleOnCollapseChange = (value) => {
    setActiveKey(value);
  };
  const handleOnCancel = () => {
    const { history } = props;
    history.push({
      pathname: '/overview'
    });
    close();
  };

  const handleOnConfirm = async () => {
    const {
      isPorhibitedOnly,
      isDisplayOnly,
      handleUpdateFeaturesConfigurations
    } = props;
    if (isPorhibitedOnly && !isDisplayOnly) {
      handleUpdateFeaturesConfigurations('isProhibitedItemsModalViewed');
    }
    close();
  };
  return (
    <Modal
      title={null}
      width={null}
      wrapClassName="br-prohibited-items-uncoverd-zones__modal"
      footer={
        !props.isDisplayOnly ? (
          <>
            <BRButton
              className="button-md"
              label={intl.formatMessage({ id: 'common.cancel' })}
              onClick={handleOnCancel}
            />
            <BRButton
              type="primary"
              className="button-md"
              label={intl.formatMessage({
                id: 'prohibited_items_modal.acknowledge'
              })}
              onClick={handleOnConfirm}
            />
          </>
        ) : (
          <BRButton
            type="primary"
            className="button-md"
            label={intl.formatMessage({
              id: 'common.got_it'
            })}
            onClick={() => close()}
          />
        )
      }
      {...props}
    >
      <div className="br-prohibited-items-uncoverd-zones__container">
        <div className="br-prohibited-items-uncoverd-zones__header">
          <div className="display-md">
            {intl.formatMessage({ id: 'prohibited_items_modal.title' })}
          </div>
          <div className="br-prohibited-items-uncoverd-zones__subtitle">
            {intl.formatMessage({ id: 'prohibited_items_modal.subtitle' })}
          </div>
        </div>
        <Collapse
          accordion
          bordered={false}
          onChange={handleOnCollapseChange}
          activeKey={activeKey}
        >
          {PROHIBITED_ITEMS_MODAL_COLLAPSE_TITLE(props?.isPorhibitedOnly).map(
            ({ title, subtitle, icon: Icon, content: Content }, index) => (
              <Collapse.Panel
                key={index}
                showArrow={false}
                extra={
                  activeKey === index.toString() ? <UpArrow /> : <DownArrow />
                }
                header={
                  <div className="br-prohibited-items-uncoverd-zones__collapse-header">
                    <div className="br-prohibited-items-uncoverd-zones__collapse-icon">
                      <Icon />
                    </div>
                    <div className="br-prohibited-items-uncoverd-zones__collapse-content">
                      <div className="heading">{title}</div>
                      <div className="br-prohibited-items-uncoverd-zones__collapse-subtitle">
                        {subtitle}
                      </div>
                    </div>
                  </div>
                }
              >
                <Content />
              </Collapse.Panel>
            )
          )}
        </Collapse>
        <BannerContent
          type="info"
          icon={<TooltipIcon />}
          subText={intl.formatMessage(
            {
              id: 'prohibited_items_modal.banner'
            },
            {
              span: (children) => (
                <span className="body-medium">{children}</span>
              )
            }
          )}
        />
      </div>
    </Modal>
  );
};

export default ProhibitedItemsAndUncoveredZones;
