import { useState, useEffect, useMemo, useCallback } from 'react';
import useMedia from 'use-media';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

export const setPageTitle = (title) => {
  document.title = `${fmt({ id: 'common.bosta' })} - ${title}`;
};

export const usePageTitle = (title) => {
  useEffect(() => {
    setPageTitle(title);
    return () => {
      setPageTitle('Bosta Business Dashboard');
    };
  }, [title]);
};

export const useOnScreen = (ref, threshold = 0.6, delay = 50) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        {
          root: document.querySelector('#scrollArea'),
          threshold,
          delay
        }
      ),
    [ref]
  );

  useEffect(() => {
    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
};

export const useOutsideClicks = ({ ref, action }) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

export const mediaHook = (Component) => {
  return function WrappedComponent(props) {
    const isTinylMobileScreen = useMedia({ maxWidth: '328px' });
    const isTinyMobileScreen2 = useMedia({ maxWidth: '375px' });
    const isSmallMobileScreen = useMedia({ maxWidth: '576px' });
    const isMediumMobileScreen = useMedia({ maxWidth: '768px' });
    const isMediumMobileScreen2 = useMedia({ maxWidth: '820px' });
    const isLargeMobileScreen = useMedia({ maxWidth: '992px' });
    const isLargeMobileScreen2 = useMedia({ maxWidth: '1280px' });
    const isXLargeMobileScreen = useMedia({ maxWidth: '1440px' });
    const mobileScreenSizes = {
      isTinylMobileScreen,
      isTinyMobileScreen2,
      isSmallMobileScreen,
      isMediumMobileScreen,
      isLargeMobileScreen,
      isLargeMobileScreen2,
      isXLargeMobileScreen,
      isMediumMobileScreen2
    };
    return <Component {...props} mobileScreenSizes={mobileScreenSizes} />;
  };
};

export const DnDHook = (Component) => {
  return function WrappedComponent(props) {
    return (
      <DndProvider backend={HTML5Backend}>
        <Component {...props} />
      </DndProvider>
    );
  };
};

export const useWindowResize = (
  callback,
  { callOnMount = false, callInTimeout = true, timeoutDuration = 0 } = {}
) => {
  useEffect(() => {
    window.addEventListener('resize', callback);

    const timeout = callInTimeout && setTimeout(callback, timeoutDuration ?? 0);

    callOnMount && callback();

    const cleanup = () => {
      window.removeEventListener('resize', cleanup);
      timeout && clearTimeout(timeout);
    };
    return cleanup;
  }, [callback, callOnMount, callInTimeout, timeoutDuration]);
};

export const useElementPoints = (element, resizeHookOptions = {}) => {
  const [points, setPoints] = useState({
    xStart: 0,
    xEnd: 0,
    yStart: 0,
    yEnd: 0,
    width: 0,
    height: 0
  });


  const pointsHandler = useCallback(() => {
    const isRtl = document.dir.toLowerCase() === 'rtl';

    if (!element) {
      return;
    }
    const { width, height, left, right, top, bottom } =
      element.getBoundingClientRect();

    const xStart = isRtl ? right : left;
    const xEnd = isRtl ? left : right;

    setPoints({
      xStart,
      xEnd,
      yStart: top,
      yEnd: bottom,
      width,
      height
    });
  }, [element]);

  useWindowResize(pointsHandler, resizeHookOptions);

  return points;
};
