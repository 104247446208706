import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import {
  Form,
  Select,
  Button,
  Modal,
  DatePicker,
  Checkbox,
  Input,
  Tooltip
} from 'antd';
import Icon from '@ant-design/icons';
import dayjs from 'dayjs';
import classNames from 'classnames';

import { cleanEmptyString, checkDate, getNextBusinessDay } from 'utils/helpers';
import { ModalContext } from 'contexts/modalProvider.context';
import {
  checkIfPickupFeesIsApplicable,
  disabledDate,
  handleDateAccordingToServer
} from 'utils/pickups';
import { sendSegment } from 'utils/segment';

import {
  fetchPickupLocations,
  fetchPickupLocation,
  createPickup,
  editPickup,
  fetchPickup,
  getHolidays
} from 'services/pickups';
import { checkIfBulkyDeliveriesExist } from 'services/shipments';
import {
  PICKUP_DATE_QUICK_FILTERS,
  DAYS,
  PICKUP_PACKAGE_TYPE,
  REPEATED_TYPE_SELECTION,
  DATE_FORMAT,
  DATE_FORMAT_WITH_DAY,
  CANCELED_PICKUP_CONFIGURATION_KEY,
  MIN_CHARGABLE_PICKUPS,
  COST_PER_CHARGABLE_PICKUP,
  LOCATION_ID_FIELD_NAMES
} from 'constants/pickups';
import { PROHIBITED_ITEMS_MODAL_LIST } from 'constants/home-page-activation';
import { LOCALE } from 'constants/intl-wrapper';
import { DAYS_OF_WEEK } from 'constants/helpers';
import {
  getCurrency,
  getCurrentUserCountryData
} from 'constants/countries/countries-mapping';
import { minNumberRule, pickupLocationsAreaRequired } from 'utils/forms';
import { editPickupLocation } from 'services/pickup-locations';

import { notify } from 'components/Notify/Notify';
import CreatePickupLocationFormModal from 'components/Pickups/components/CreatePickupLocationFormModal/CreatePickupLocationFormModal';
import CreateContactPersonFormModal from 'components/Pickups/components/CreateContactPersonFormModal/CreateContactPersonFormModal';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';
import BRButton from 'components/BRButton/BRButton';
import ActivationModal from 'components/BRModals/ActivationModal/ActivationModal';
import SchedulingPickupModal from 'components/BRModals/SchedulingPickupModal/SchedulingPickupModal';
import BRInfoBanner from 'components/BRInfoBanner/BRInfoBanner';
import CreateOrderModal from '../CreateOrderModal/CreateOrderModal';
import CancelledPickupsBanner from '../CancelledPickupsBanner/CancelledPickupsBanner';
import IncompleteLocationModal from 'components/Pickups/components/IncompleteLocationModal/IncompleteLocationModal';
import BRCityAreaCollapse from 'components/BRCityAreaCollapse/BRCityAreaCollapse';

import { ReactComponent as DatePickerIcon } from 'assets/bosta-icons/Calendar.svg';
import { ReactComponent as PlusIcon } from 'assets/bosta-icons/Plus.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/bosta-icons/Arrow-right.svg';
import { ReactComponent as Rocket } from 'assets/bosta-icons/rocket.svg';

import './CreateEditPickupModal.less';

class CreateEditPickupModal extends Component {
  state = {
    pickupLocations: [],
    isLoading: false,
    pickupLocation: null,
    scheduledDate: null,
    repeatedPickup: {
      isRepeated: false,
      days: [],
      endDate: null
    },
    contactPerson: {},
    fullContactPersonData: {},
    onBoarding: false,
    createNewContact: false,
    isNewZoning: true,
    locationId: null,
    cancelUpdate: false,
    withNotes: false,
    bulkyOptions: {
      lightBulky: false,
      heavyBulky: false
    },
    showRepeatedPickups: false,
    isSchedulingPickupModalVisible: false,
    openedFromScedulingPickModal: false,
    isPrepareOrdersBannerVisible: false,
    holidaysDates: [],
    showNumberOfParcelsWarning: false,
    isLocationsListDropdownVisible: false,
    isBulkyPickup: false
  };

  formRef = React.createRef();
  datePickerRef = React.createRef();
  static contextType = ModalContext;

  onFinish = async (values) => {
    const { openModal } = this.context;

    this.setState({ isLoading: true });
    const { isUserFullyActivated } = JSON.parse(
      localStorage.getItem('userInfo')
    )?.user;

    const { intl } = this.props;
    const {
      businessInfo,
      setBusinessInfo,
      handleUpdateFeaturesConfigurations
    } = this.context;

    const canceledPickups =
      businessInfo?.featuresConfigurations?.canceledPickupsWaitingForAction ||
      [];

    if (!isUserFullyActivated && !businessInfo.isRegisteredViaBetaFunnel) {
      this.setState({ isLoading: false });
      openModal(ActivationModal, {
        icon: <Icon component={Rocket} />,
        title: intl.formatMessage({
          id: 'new_header.orders.activation_modal.title'
        }),
        subtitle: intl.formatMessage({
          id: 'new_header.orders.activation_modal.subtitle'
        }),
        buttonText: intl.formatMessage({
          id: 'new_header.orders.activation_modal.buttonText'
        })
      });
    } else {
      const { close, onSuccess = () => {}, intl } = this.props;
      const {
        scheduledDate,
        fullContactPersonData,
        cancelUpdate,
        bulkyOptions,
        repeatedPickup
      } = this.state;
      if (!cancelUpdate) {
        delete fullContactPersonData?.firstName;
        delete fullContactPersonData?.lastName;
        delete fullContactPersonData?.isDefault;
        const payload = {
          businessLocationId: values?.locationId,
          contactPerson: cleanEmptyString(fullContactPersonData),
          scheduledDate: dayjs(scheduledDate).format('YYYY-MM-DD'),
          notes: values?.notes,
          numberOfParcels: values.numberOfParcels,
          ...(values?.isBulkyPickup && {
            packageType: bulkyOptions?.heavyBulky
              ? PICKUP_PACKAGE_TYPE.HEAVY_BULKY
              : PICKUP_PACKAGE_TYPE.LIGHT_BULKY
          }),
          hasFragileItems: values?.isFragileItem,
          hasBigItems: values?.isBigItem,
          ...(repeatedPickup?.isRepeated
            ? {
                repeatedData: {
                  repeatedType:
                    REPEATED_TYPE_SELECTION[
                      repeatedPickup?.days?.length === 6 ? 0 : 1
                    ].value,
                  days: repeatedPickup?.days.map(
                    (dayIndex) => DAYS[dayIndex].value
                  ),
                  startDate: scheduledDate,
                  endDate: repeatedPickup?.endDate
                }
              }
            : {
                repeatedData: {
                  repeatedType: 'One Time'
                }
              }),
          ...(values.subAccountId && { subAccountId: values.subAccountId })
        };
        try {
          this.setState({ isLoading: true });
          if (this.props.pickupId) {
            delete payload.contactPerson._id;
            const pickupId = this.props.pickupId;
            await editPickup({ id: pickupId, payload });
            sendSegment('E_PICKUP_EDITED_COMPLETED', {
              // 'Edited Field Name': changedFields,
              // 'Matching Hub': pickupMatchedHub,
              'Pickup Request ID': pickupId
            });

            notify({
              msg: intl.formatMessage({
                id: `pickups.create_edit_pickup.edit_pickup_success`
              }),
              type: 'success'
            });
            close();
            onSuccess();
          } else {
            const { puid } = await createPickup({
              ...payload,
              creationSrc: window.screen.width > 992 ? 'Web' : 'Web Responsive',
              sessionId: localStorage.getItem('ajs_anonymous_id')
            });

            if (this.props?.isCanceledPickup) {
              handleUpdateFeaturesConfigurations(
                CANCELED_PICKUP_CONFIGURATION_KEY,
                canceledPickups.filter(
                  (pickup) => pickup.pickupId !== this.props?.cancledPickupId
                )
              );
            }
            sendSegment('E_PICKUP_CREATED', {
              'is Heavy Bulky':
                bulkyOptions?.heavyBulky && values?.isBulkyPickup
                  ? 'Yes'
                  : 'No',
              'is Light Bulky':
                bulkyOptions?.lightBulky && values?.isBulkyPickup ? 'Yes' : 'No'
            });
            if (repeatedPickup?.isRepeated) {
              sendSegment('E_PICKUP_REPEATED', {
                repeatedType:
                  REPEATED_TYPE_SELECTION[
                    repeatedPickup?.days?.length === 6 ? 0 : 1
                  ].value,
                days: repeatedPickup.days,
                endDate: repeatedPickup.endDate,
                startDate: scheduledDate
              });
            }
            if (!businessInfo?.isFirstPickupCreated) {
              const userInfo = JSON.parse(localStorage.getItem('userInfo'));
              userInfo.user.isFirstPickupCreated = true;
              localStorage.setItem('userInfo', JSON.stringify(userInfo));
              sendSegment('Step3_First-pickup-created');
              sendSegment('Step4_First-order-completed-successfully');
              sendSegment('Step7_First-pickup-scheduled-successfully', {
                Account_Type: 'Bosta'
              });
              setBusinessInfo({
                ...businessInfo,
                isFirstPickupCreated: true
              });
            }
            notify({
              msg: intl.formatMessage({
                id: `new_order.request_pickup.pickup_created_successfully`
              }),
              type: 'success'
            });

            close();
            onSuccess(puid);
          }
        } catch (error) {
          notify({ msg: error.message, error });
        }
        this.setState({ isLoading: false });
      } else {
        notify({
          msg: intl.formatMessage({
            id: `pickups.create_edit_pickup.error_create_new_zoning`
          })
        });
      }
    }
    this.setState({ isLoading: false });
  };

  onFinishFailed = ({ errorFields }) => {
    const { pickupLocation } = this.state;
    const { intl } = this.props;
    const { openModal } = this.context;

    if (
      pickupLocation?._id &&
      errorFields?.length === 1 &&
      LOCATION_ID_FIELD_NAMES.includes(errorFields[0]?.name[0])
    ) {
      openModal(IncompleteLocationModal, {
        locationId: pickupLocation?._id,
        getPickupLocations: () =>
          this.getPickupLocations({ selectedLocationId: pickupLocation?._id }),
        onChangeAddress: () =>
          this.handleOnLocationsDropdownVisibleChange(true),
        title: intl.formatMessage({
          id: 'incomplete_location_modal.pickup_title'
        })
      });
    }
  };

  handleOnValuesChange = (_, allValues) => {
    const { numberOfParcels } = allValues;
    const { businessInfo } = this.context;

    if (checkIfPickupFeesIsApplicable(businessInfo)) {
      this.setState({
        showNumberOfParcelsWarning:
          parseInt(numberOfParcels) < MIN_CHARGABLE_PICKUPS
      });
    }
  };

  getPickupLocations = async ({ selectedLocationId } = {}) => {
    try {
      const { onBoarding, locationId, isNewZoning } = this.state;
      this.setState({ isLoading: true });
      const { list } = await fetchPickupLocations();

      this.setState({
        pickupLocations: list,
        isLoading: false
      });
      if (!onBoarding) {
        this.selectedValueOnboarding({ selectedLocationId });
      } else {
        if (this.props.pickupId) {
          return;
        }

        let id;

        if (!selectedLocationId) {
          id = list.find((item) => item.isDefault)?._id;
          this.getPickupLocation(id);
        } else {
          this.formRef.current?.validateFields([['locationId']]);
        }

        this.formRef.current?.setFieldsValue({
          locationId: selectedLocationId
            ? selectedLocationId
            : isNewZoning
            ? id
            : locationId
        });
      }
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  fetchHolidays = async () => {
    try {
      const payload = {
        countryCode: getCurrentUserCountryData().codeName
      };

      const data = await getHolidays(payload);

      const holidaysDates = data
        .filter((holiday) => holiday?.stopSchedulingPickups)
        .map(({ holidayDate }) => dayjs(holidayDate, 'DD-MM-YYYY'));

      this.setState({
        holidaysDates
      });
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  checkPickupLocation = (PickupLocation) => {
    const { openModal } = this.context;

    if (PickupLocation?.isNewZoning) {
      this.setState({ isNewZoning: true });
    } else {
      this.setState({ isNewZoning: false });
      openModal(CreatePickupLocationFormModal, {
        pickupLocation: PickupLocation,
        onSuccess: () => {
          this.setState({ locationId: PickupLocation._id }, () => {
            this.getPickupLocations();
          });
        },
        onCancel: () => {
          this.setState({ cancelUpdate: true });
        }
      });
    }
  };

  getPickupLocation = async (locationId, contactPersonName) => {
    try {
      this.setState({ isLoading: true });
      const data = await fetchPickupLocation(locationId);
      this.setState({ pickupLocation: data });

      this.checkPickupLocation(data);
      this.formRef.current?.setFieldsValue({
        contactPerson: contactPersonName
          ? contactPersonName
          : data?.contacts[data?.contacts?.length - 1]?.name
      });
      this.setState({
        isLoading: false,
        fullContactPersonData: cleanEmptyString(
          data?.contacts[data?.contacts?.length - 1]
        )
      });
      this.formRef.current?.validateFields([['locationId']]);
      return data;
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  handlePickupLocationChange = (locationId) => {
    this.getPickupLocation(locationId);
    const { pickupLocation } = this.state;
    this.formRef.current.setFieldsValue({
      locationId,
      contactPerson: pickupLocation?.contacts[0]?.name
    });
  };

  handleOnChangePickupDate = (scheduledDate) => {
    this.setState({
      scheduledDate,
      repeatedPickup: {
        ...this.state.repeatedPickup,
        endDate: scheduledDate.add(4, 'week')
      }
    });
  };

  handleOnChangeEndDate = (endDate) => {
    this.setState({
      repeatedPickup: {
        ...this.state.repeatedPickup,
        endDate
      }
    });
  };

  onChangeContactPerson = (contactPerson) => {
    const { pickupLocation } = this.state;
    let newPerson;
    pickupLocation.contacts.map((person) => {
      if (person?.name === contactPerson) {
        newPerson = person.name;
        this.setState({
          contactPerson: newPerson,
          fullContactPersonData: person
        });
      }

      return null;
    });
  };

  selectedValueOnboarding({ selectedLocationId }) {
    const { pickupLocations, holidaysDates } = this.state;
    const { serverTimeData } = this.context;
    const id = pickupLocations.find((item) => item.isDefault)?._id;
    const serverToday = handleDateAccordingToServer(serverTimeData);
    const nextBusinessDay = getNextBusinessDay(serverToday, holidaysDates);

    this.getPickupLocation(id);

    this.formRef.current.setFieldsValue({
      locationId: selectedLocationId ?? id,
      scheduledDate: nextBusinessDay
    });
    this.setState({ scheduledDate: nextBusinessDay });
  }

  handleDatePickerFooterClick = (daysToAdd) => {
    this.formRef.current.setFieldsValue({
      scheduledDate: dayjs.utc(new Date()).add(daysToAdd, 'days')
    });
    this.setState({
      scheduledDate: dayjs.utc(new Date()).add(daysToAdd, 'days')
    });
    this.datePickerRef.current.blur();
  };

  datePickerFooter = () => {
    //index is equal to the number of days to be added because the filters are today and tomorrow i.e add 0 and 1 days respectively to today's date
    const { scheduledDate } = this.state;
    const { pickupCutoffTime, serverTimeData } = this.context;
    const serverTime = handleDateAccordingToServer(serverTimeData);

    return (
      <div className="br-request-pickup-modal__date-picker__footer">
        {PICKUP_DATE_QUICK_FILTERS.map(({ label, valueInDays }) => {
          const targetDay = serverTime.add(valueInDays, 'days');
          const targetIsScheduled = dayjs(scheduledDate).isSame(
            targetDay,
            'days'
          );

          if (targetDay.day() === DAYS_OF_WEEK.FRIDAY) {
            return <></>;
          }

          if (valueInDays === 0 && serverTime.hour() >= pickupCutoffTime) {
            return <></>;
          }

          return (
            <Button
              key={valueInDays}
              type={targetIsScheduled ? 'primary' : ''}
              className="br-request-pickup-modal__date-picker__footer-btn"
              onClick={() => {
                this.handleDatePickerFooterClick(valueInDays);
              }}
            >
              {label}
            </Button>
          );
        })}
      </div>
    );
  };

  datePickerCellRender = (current) => {
    const { intl } = this.props;
    const { pickupCutoffTime, serverTimeData } = this.context;
    const formattedUserHour = dayjs()
      .utcOffset(serverTimeData?.timeZone?.utcOffset || 0)
      .hour();
    const currentDayOfMonth =
      handleDateAccordingToServer(serverTimeData).get('date');

    const formattedCurrent = handleDateAccordingToServer(serverTimeData, {
      value: current
    }).get('date');

    if (
      formattedCurrent === currentDayOfMonth &&
      formattedUserHour >= pickupCutoffTime
    ) {
      return (
        <Tooltip
          getPopupContainer={(trigger) =>
            trigger.parentElement.parentElement.parentElement.parentElement
              .parentElement.parentElement.parentElement
          }
          title={intl.formatMessage(
            {
              id: 'new_order.request_pickup_modal.disabled_date_tooltip'
            },
            {
              hour:
                pickupCutoffTime > 12
                  ? pickupCutoffTime - 12
                  : pickupCutoffTime || 12
            }
          )}
        >
          <div className="ant-picker-cell-inner br-request-pickup-modal__form__date-picker-dropdown__with-tooltip">
            {handleDateAccordingToServer(serverTimeData, {
              value: current
            }).get('date')}
          </div>
        </Tooltip>
      );
    } else {
      return <div className="ant-picker-cell-inner">{current.date()}</div>;
    }
  };

  handleRepeatedPickupDayChange = (dayIndex) => {
    const {
      repeatedPickup: { days }
    } = this.state;
    const newDaysArray = days;
    const indexOfSelectedDay = days.findIndex((value) => value === dayIndex);
    if (indexOfSelectedDay >= 0 && days.length > 1) {
      delete newDaysArray[indexOfSelectedDay];
    } else {
      indexOfSelectedDay < 0 && newDaysArray.push(dayIndex);
    }
    this.setState({
      repeatedPickup: {
        ...this.state.repeatedPickup,
        days: newDaysArray.filter((n) => n !== undefined)
      }
    });
  };

  checkBulkyOption = async () => {
    try {
      const { hasHeavyBulkyType, hasLightBulkyType } =
        await checkIfBulkyDeliveriesExist();
      const hasBulkyOptions = hasHeavyBulkyType || hasLightBulkyType;
      this.setState({
        isBulkyPickup: hasBulkyOptions,
        bulkyOptions: {
          heavyBulky: hasHeavyBulkyType,
          lightBulky: !hasHeavyBulkyType && hasLightBulkyType
        }
      });
      this.formRef.current?.setFieldsValue({
        isBulkyPickup: hasBulkyOptions,
        isBigItem: hasBulkyOptions
      });
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  checkShowRepeatedPickups = () => {
    const {
      businessInfo: { allowRecurringPickups }
    } = this.context;

    this.setState({
      showRepeatedPickups: allowRecurringPickups
    });
  };

  handleDaysToIndex = (days) => {
    const indexDays = [];
    days.forEach((day) => {
      indexDays.push(DAYS.findIndex(({ value }) => value === day));
    });
    return indexDays;
  };

  getPickupInfo = async () => {
    const { isNewZoning } = this.state;
    try {
      const { pickupId } = this.props;
      const {
        businessLocationId,
        scheduledDate,
        contactPerson: { name, phone, secPhone, email },
        repeatedData,
        notes,
        numberOfParcels,
        hasBigItems,
        hasFragileItems,
        packageType
      } = await fetchPickup(pickupId);
      this.getPickupLocation(businessLocationId, name);
      const date = dayjs.utc(new Date(scheduledDate));
      const isBulkyPickup = [
        PICKUP_PACKAGE_TYPE.LIGHT_BULKY,
        PICKUP_PACKAGE_TYPE.HEAVY_BULKY
      ].includes(packageType);
      this.formRef.current?.setFieldsValue({
        locationId: isNewZoning ? businessLocationId : null,
        scheduledDate: date,
        contactPerson: name,
        phone,
        secPhone,
        email,
        notes,
        numberOfParcels,
        isBigItem: hasBigItems,
        isFragileItem: hasFragileItems,
        isBulkyPickup
      });
      this.handleOnChangePickupDate(date);
      const endDate = dayjs.utc(new Date(repeatedData?.endDate));
      const isFriday = date.get('day') === 5;
      this.setState({
        isBulkyPickup,
        repeatedPickup: {
          ...this.state.repeatedPickup,
          isRepeated: repeatedData?.repeatedType !== 'One Time',
          days:
            repeatedData?.days?.length > 0
              ? this.handleDaysToIndex(repeatedData?.days)
              : [isFriday ? 0 : (date.get('day') + 1) % 7],
          endDate: endDate.isValid() ? endDate : dayjs.utc(date.add(4, 'week'))
        }
      });
      if (notes && notes !== 'N/A') {
        this.setState({ withNotes: true });
      }
    } catch (error) {
      notify({ msg: error.message, error });
    }
  };

  doOnMount = () => {
    const isFirstOrderCreated = JSON.parse(localStorage.getItem('userInfo'))
      .user.isFirstOrderCreated;
    const { pickupCutoffTime, serverTimeData } = this.context;
    const { holidaysDates } = this.state;

    this.setState(
      {
        onBoarding:
          isFirstOrderCreated === undefined || isFirstOrderCreated
            ? true
            : isFirstOrderCreated
      },
      () => {
        this.getPickupLocations();
        this.fetchHolidays();
        this.checkBulkyOption();
        this.checkShowRepeatedPickups();
      }
    );

    if (isFirstOrderCreated === undefined || isFirstOrderCreated) {
      const formattedUserTime = handleDateAccordingToServer(serverTimeData);
      const scheduledDateDisabled = disabledDate(
        formattedUserTime,
        formattedUserTime,
        pickupCutoffTime,
        holidaysDates
      );
      let scheduledDate = handleDateAccordingToServer(serverTimeData);
      if (scheduledDateDisabled) {
        scheduledDate = getNextBusinessDay(scheduledDate, holidaysDates);
      }

      this.formRef.current?.setFieldsValue({
        scheduledDate: scheduledDate
      });

      //dayjs get days method is shifted by 1 so sunday is day at index 0 instead of 1 compared to the DAYS constant imported so we add 1 and mod by 7 for saturday
      //at index 6 to map to (1+6)%7 = 0

      const isFriday = scheduledDate.get('day') === 5;
      if (!this.props.pickupId) {
        this.setState({
          scheduledDate: scheduledDate,
          repeatedPickup: {
            ...this.state.repeatedPickup,
            days: [scheduledDate.day()],
            endDate: scheduledDate.add(4, 'week')
          }
        });
      }
    }
    if (this.props.pickupId) {
      this.getPickupInfo();
    }
  };

  componentDidMount() {
    const canCreatePickup = JSON.parse(localStorage.getItem('canCreatePickup'));
    const { hideSchedulingPickupModal, close } = this.props;
    const { openModal, businessInfo } = this.context;

    const isFirstPickupCreated = businessInfo?.isFirstPickupCreated;

    if (!canCreatePickup && !businessInfo?.internationalShippingActivated) {
      openModal(CreateOrderModal);
      return close();
    }

    this.setState({
      isSchedulingPickupModalVisible: hideSchedulingPickupModal
        ? !hideSchedulingPickupModal
        : !isFirstPickupCreated,
      isPrepareOrdersBannerVisible:
        !isFirstPickupCreated &&
        !businessInfo?.featuresConfigurations?.isPrepareOrdersBannerDismissed
    });

    if (!businessInfo?.isFirstPickupCreated && !hideSchedulingPickupModal) {
      this.handleOpenSchedulingPickupModal();
    } else {
      this.doOnMount();
    }
  }

  handleOpenSchedulingPickupModal = () => {
    const { openModal, businessInfo } = this.context;

    this.setState({
      isSchedulingPickupModalVisible: !businessInfo?.isFirstPickupCreated
    });

    openModal(SchedulingPickupModal, {
      closeParentModal: this.props.close,
      backToParentModal: () => {
        this.setState({
          isSchedulingPickupModalVisible: false,
          openedFromScedulingPickModal: true
        });
        this.doOnMount();
      }
    });
  };

  handleCreateContactPersonClick = () => {
    const { intl } = this.props;
    const { pickupLocation } = this.state;
    const { openModal, businessInfo } = this.context;

    if (pickupLocation?.canEdit) {
      openModal(CreateContactPersonFormModal, {
        pickupLocation,
        getPickupLocation: this.getPickupLocation,
        businessInfo
      });
    } else {
      notify({
        msg: intl.formatMessage({
          id: !this.formRef.current.getFieldValue('locationId')
            ? 'settings.pickup_locations.pickup_location_form.add_contact_before_location_error'
            : 'settings.pickup_locations.pickup_location_form.add_contact_error'
        }),
        status: -1
      });
    }
  };

  handleAddPickupLocationClick = (e) => {
    const { openModal } = this.context;

    openModal(CreatePickupLocationFormModal, {
      onSuccess: (pickupLocationId) => {
        this.getPickupLocations(false);
        this.handlePickupLocationChange(pickupLocationId);
      }
    });
  };

  customSelectDropdownFooter = () => {
    const { intl } = this.props;
    return (
      <div className="br-request-pickups-modal__select-dropdown-footer display-flex body">
        <div className="br-request-pickups-modal__select-dropdown-footer-title">
          {intl.formatMessage({
            id: `new_order.request_pickup_modal.select_footer`
          })}
        </div>
        <BRButton
          type="link-color"
          label={intl.formatMessage({ id: 'nav_bar.settings' })}
          className="button-md"
          suffixIcon={<ArrowRightIcon />}
          onClick={() => window.open('/settings/business-locations', '_blank')}
        />
      </div>
    );
  };

  renderLocationDropdown = (menu) => {
    const { intl } = this.props;
    return (
      <>
        <div className="br-request-pickups-modal__select-dropdown-header">
          <BRButton
            type="link-color"
            label={intl.formatMessage({
              id: `pickups.actions.add_location`
            })}
            className="button-md"
            prefixIcon={<PlusIcon />}
            onClick={this.handleAddPickupLocationClick}
          />
        </div>
        {menu}
        {this.customSelectDropdownFooter()}
      </>
    );
  };

  renderContactPersonDropdown = (menu) => {
    const { intl } = this.props;
    return (
      <>
        {menu}
        {this.customSelectDropdownFooter()}
      </>
    );
  };

  handlePrepareOrdersGuide = ({ isFromPickupModal = true }) => {
    const { handleOpenPrepareOrderModal, openModal } = this.context;
    const { history, close } = this.props;

    close();
    handleOpenPrepareOrderModal({
      openModal,
      history,
      source: 'PickupPage',
      isFromPickupModal: isFromPickupModal
    });
  };

  handleDismissInfoBanner = async () => {
    this.setState({ isLoading: true });
    const { handleUpdateFeaturesConfigurations } = this.context;
    await handleUpdateFeaturesConfigurations('isPrepareOrdersBannerDismissed');
    this.setState({ isLoading: false });
  };

  handleCancel = () => {
    const { close, hideSchedulingPickupModal } = this.props;
    const { openedFromScedulingPickModal } = this.state;

    openedFromScedulingPickModal || hideSchedulingPickupModal
      ? this.handleOpenSchedulingPickupModal()
      : close();
  };

  handleUpdateLocationDistrict = async (districtId) => {
    this.setState({ isLoading: true });

    const { pickupLocation } = this.state;

    try {
      delete pickupLocation.address.geoLocation;
      delete pickupLocation.address.userEnteredGeoLocation;

      const payload = {
        locationName: pickupLocation.locationName,
        contacts: pickupLocation.contacts,
        address: {
          ...pickupLocation.address,
          districtId
        }
      };

      payload.contacts.map((contact) => {
        if (contact._id === pickupLocation.contactPerson._id) {
          contact.isDefault = true;
        }
        return contact;
      });

      await editPickupLocation({
        pickupLocationId: pickupLocation._id,
        payload
      });
      this.getPickupLocations({ selectedLocationId: pickupLocation._id });
    } catch (error) {
      notify({ msg: error.message, error });
    }

    this.setState({ isLoading: false });
  };

  handleUpdateMissingAreaClick = () => {
    const { openModal } = this.context;

    openModal(BRCityAreaCollapse, {
      setCityArea: (value) =>
        this.handleUpdateLocationDistrict(value?.districtId)
    });
  };

  handleOnLocationsDropdownVisibleChange = (value) => {
    this.setState({ isLocationsListDropdownVisible: value });
  };

  pickupDisabledDates = (current) => {
    const { holidaysDates } = this.state;
    const { pickupCutoffTime, serverTimeData } = this.context;

    const formattedUserTime = handleDateAccordingToServer(serverTimeData);
    return disabledDate(
      current,
      formattedUserTime,
      pickupCutoffTime,
      holidaysDates
    );
  };

  handleIsBulkyOnChange = (e) => {
    const isBulkyPickupChecked = e.target.checked;
    this.setState({
      isBulkyPickup: isBulkyPickupChecked
    });
    this.formRef.current?.setFieldsValue({ isBigItem: isBulkyPickupChecked });
  };

  render() {
    const { intl, pickupId } = this.props;
    const {
      pickupLocations,
      pickupLocation,
      isLoading,
      onBoarding,
      repeatedPickup,
      withNotes,
      bulkyOptions,
      isBulkyPickup,
      showRepeatedPickups,
      isSchedulingPickupModalVisible,
      scheduledDate,
      isPrepareOrdersBannerVisible,
      showNumberOfParcelsWarning,
      isLocationsListDropdownVisible
    } = this.state;
    const { businessInfo } = this.context;

    const isUserFullyActivated = JSON.parse(localStorage.getItem('userInfo'))
      ?.user?.isUserFullyActivated;

    if (isSchedulingPickupModalVisible) return null;
    return (
      <Modal
        {...this.props}
        wrapClassName="br-request-pickup-modal"
        title={null}
        width={null}
        footer={
          <>
            <BRButton
              disabled={isLoading}
              onClick={this.handleCancel}
              label={intl.formatMessage({
                id: 'common.cancel'
              })}
              type="basic"
            />

            <BRButton
              loading={isLoading}
              className="br-registration-form-card__submit-btn"
              form="requestPickupForm"
              type="primary"
              htmlType="submit"
              label={intl.formatMessage({
                id: `common.confirm`
              })}
            />
          </>
        }
        onCancel={this.handleCancel}
      >
        <BRContentHeader
          title={intl.formatMessage({
            id: pickupId
              ? `pickups.create_edit_pickup.pickup_edit_title`
              : `new_order.request_pickup_modal.title`
          })}
          subtitle={
            !pickupId &&
            intl.formatMessage({
              id: `new_order.request_pickup_modal.subtitle`
            })
          }
          isInternalComponent
        />
        <Form
          className="br-request-pickup-modal__form"
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          onValuesChange={this.handleOnValuesChange}
          id="requestPickupForm"
          ref={this.formRef}
        >
          {isPrepareOrdersBannerVisible && (
            <BRInfoBanner
              className="br-request-pickup-modal__info-banner"
              content={
                <div>
                  <div>
                    {intl.formatMessage({
                      id: `new_order.request_pickup_modal.info_banner.title`
                    })}
                  </div>
                  <div className="br-request-pickup-modal__info-banner__btns">
                    <BRButton
                      label={intl.formatMessage({
                        id: `new_order.request_pickup_modal.info_banner.secondary_btn`
                      })}
                      onClick={this.handlePrepareOrdersGuide}
                    />
                    <BRButton
                      type="treitary-gray"
                      label={intl.formatMessage({
                        id: `new_order.request_pickup_modal.info_banner.text_btn`
                      })}
                      onClick={this.handleDismissInfoBanner}
                    />
                  </div>
                </div>
              }
            />
          )}
          {!repeatedPickup.isRepeated && (
            <div className="br-form-row">
              <Form.Item
                className="number-of-parcels"
                name="numberOfParcels"
                label={
                  <div className="number-of-parcels-wrapper">
                    <div className="br-create-edit-pickup__number-of-parcels-header">
                      {intl.formatMessage({
                        id: `new_order.request_pickup_modal.form_labels.number_of_parcels`
                      })}
                      <Tooltip
                        overlayClassName="prohibited-items-tooltip"
                        getPopupContainer={(trigger) =>
                          trigger.parentElement.parentElement.parentElement
                            .parentElement.parentElement.parentElement
                            .parentElement
                        }
                        title={PROHIBITED_ITEMS_MODAL_LIST.map(
                          ({ labelId }, index) => (
                            <div
                              key={labelId}
                              className="br-prohibited-items-list__item"
                            >
                              <div className="body">
                                {index + 1}-{' '}
                                {intl.formatMessage({
                                  id: `prohibited_items_modal.prohibited_items.list.${labelId}.title`
                                })}
                              </div>
                            </div>
                          )
                        )}
                      >
                        <span>
                          {' '}
                          {intl.formatMessage({
                            id: 'new_order.prohibited_items'
                          })}
                        </span>
                      </Tooltip>
                    </div>
                    <span className="br-form-row__number-of-parcels-tooltip caption-lg">
                      {' '}
                      {intl.formatMessage({
                        id: `new_order.request_pickup_modal.form_labels.number_of_parcels_note`
                      })}
                    </span>
                  </div>
                }
                rules={[
                  { required: true },
                  minNumberRule({
                    message: intl.formatMessage({
                      id: 'pickup_fees_modal.minimum_number_of_parcels'
                    }),
                    min: 1
                  })
                ]}
                {...(showNumberOfParcelsWarning && {
                  validateStatus: 'warning',
                  help: intl.formatMessage(
                    { id: 'pickup_fees_modal.help_message' },
                    {
                      price: getCurrency(COST_PER_CHARGABLE_PICKUP).localized
                    }
                  )
                })}
              >
                <Input
                  type="number"
                  min="1"
                  size="large"
                  placeholder={intl.formatMessage({
                    id: `new_order.request_pickup_modal.number_of_parcels_placeholder`
                  })}
                />
              </Form.Item>
            </div>
          )}

          <div className="br-form-row">
            <Form.Item
              name="locationId"
              label={intl.formatMessage({
                id: `new_order.request_pickup_modal.form_labels.pickup_location`
              })}
              rules={[
                { required: isUserFullyActivated },
                pickupLocationsAreaRequired({
                  pickupLocations,
                  message: (
                    <div className="br-request-pickup-modal__form__area-missing-error">
                      {intl.formatMessage(
                        {
                          id: `new_order.request_pickup_modal.district_required`
                        },
                        {
                          updateButton: (
                            <BRButton
                              type="link-color"
                              className="button-md"
                              onClick={this.handleUpdateMissingAreaClick}
                              label={intl.formatMessage({
                                id: 'common.update'
                              })}
                            />
                          )
                        }
                      )}
                    </div>
                  )
                })
              ]}
            >
              <Select
                className="br-pickups__pickup-settings__form__selector br-request-pickup-modal__form__select-location-prefix"
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                onChange={this.handlePickupLocationChange}
                getPopupContainer={(trigger) => trigger.parentElement}
                autoComplete={'' + Math.random()}
                disabled={isLoading}
                dropdownRender={this.renderLocationDropdown}
                virtual={false}
                open={isLocationsListDropdownVisible}
                onDropdownVisibleChange={
                  this.handleOnLocationsDropdownVisibleChange
                }
              >
                {pickupLocations?.map(
                  ({
                    locationName,
                    address: { city, district },
                    _id,
                    isDefault
                  }) => (
                    <Select.Option
                      key={_id}
                      value={_id}
                      label={`${locationName} ${city?.name} - ${district?.name}`}
                    >
                      <div className="br-create-edit-pickup__location">
                        <div className="br-create-edit-pickup__location-item body">
                          <div>{locationName}</div>
                          <div className="br-create-edit-pickup__location-city-area">
                            {city?.name} {`${district?.name ? ',' : ''}`}
                            {district?.name}
                          </div>
                        </div>
                        {isDefault && (
                          <div className="br-create-edit-pickup__location-default subheading">
                            {intl.formatMessage({
                              id: 'common.default'
                            })}
                          </div>
                        )}
                      </div>
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
          </div>
          {pickupLocation?.cancelledPickupsCount >= 2 && (
            <CancelledPickupsBanner
              cancelledPickupsCount={pickupLocation.cancelledPickupsCount}
              handlePrepareOrdersGuide={this.handlePrepareOrdersGuide}
            />
          )}

          <div className="br-form-row contact-person">
            <Form.Item
              className="contact-person"
              name="contactPerson"
              label={
                <div>
                  <div>
                    {intl.formatMessage({
                      id: `new_order.request_pickup_modal.form_labels.contact_person`
                    })}
                  </div>
                  <span className="br-form-row__contact-person-tooltip caption-lg">
                    {intl.formatMessage({
                      id: `new_order.request_pickup_modal.form_labels.contact_person_note`
                    })}
                  </span>
                </div>
              }
              rules={[{ required: isUserFullyActivated }]}
            >
              <Select
                className="br-pickups__pickup-settings__form__selector br-request-pickup-modal__form__select-profile-prefix"
                onChange={this.onChangeContactPerson}
                autoComplete={'' + Math.random()}
                disabled={isLoading}
                dropdownRender={this.renderContactPersonDropdown}
                virtual={false}
              >
                {pickupLocation?.contacts?.length &&
                  pickupLocation.contacts.map(
                    ({ name, phone, isDefault = false }) => (
                      <Select.Option key={name} value={name}>
                        <div className="br-create-edit-pickup__location">
                          <div className="br-create-edit-pickup__location-item body">
                            <div>{name}</div>
                            <div className="br-create-edit-pickup__location-city-area">
                              {phone}
                            </div>
                          </div>
                          {isDefault && (
                            <div className="br-create-edit-pickup__location-default subheading">
                              {intl.formatMessage({
                                id: 'common.default'
                              })}
                            </div>
                          )}
                        </div>
                      </Select.Option>
                    )
                  )}
              </Select>
            </Form.Item>
          </div>

          <BRButton
            type="link-color"
            label={intl.formatMessage({
              id: `pickups.actions.add_person`
            })}
            className="button-md add-person-button"
            prefixIcon={<PlusIcon />}
            onClick={this.handleCreateContactPersonClick}
          />

          <div className="br-form-row">
            <Form.Item
              name="scheduledDate"
              label={intl.formatMessage({
                id: `new_order.request_pickup_modal.form_labels.pickup_date`
              })}
              rules={[{ required: isUserFullyActivated }]}
              initialValue={scheduledDate}
            >
              <DatePicker
                className="br-request-pickup-modal__form__date-picker"
                dropdownClassName="br-request-pickup-modal__form__date-picker-dropdown"
                placeholder={intl.formatMessage({
                  id: `new_order.request_pickup_modal.select`
                })}
                onChange={this.handleOnChangePickupDate}
                disabledDate={this.pickupDisabledDates}
                getPopupContainer={(trigger) => trigger.parentElement}
                showToday={false}
                renderExtraFooter={this.datePickerFooter}
                ref={this.datePickerRef}
                suffixIcon={<DatePickerIcon />}
                format={(value) => {
                  const day = checkDate(
                    handleDateAccordingToServer(this.context.serverTimeData, {
                      value
                    })
                  );
                  return `${day ? `${day},` : ''} ${dayjs(value).format(
                    day ? DATE_FORMAT : DATE_FORMAT_WITH_DAY
                  )}`?.trim();
                }}
                dateRender={this.datePickerCellRender}
                allowClear={false}
              />
            </Form.Item>
          </div>
          {showRepeatedPickups && (
            <div className="br-request-pickup-modal__form__repeat-pickups-container">
              <Checkbox
                checked={repeatedPickup.isRepeated}
                onChange={() => {
                  this.setState({
                    repeatedPickup: {
                      ...repeatedPickup,
                      isRepeated: !repeatedPickup.isRepeated
                    }
                  });
                }}
              >
                {intl.formatMessage({
                  id: `pickups.actions.create_repeated`
                })}
              </Checkbox>
              <span className="br-request-pickup-modal__form__repeat-pickups-subtitle">
                {intl.formatMessage({
                  id: `new_order.request_pickup_modal.repeat_pickup_subtitle`
                })}
              </span>
              <div
                className={classNames(
                  'br-request-pickup-modal__form__repeat-pickups',
                  {
                    'br-request-pickup-modal__form__repeat-pickups__visible':
                      repeatedPickup?.isRepeated
                  }
                )}
              >
                <div className="br-request-pickup-modal__form__repeat-pickups-options">
                  <span className="br-request-pickup-modal__form__repeat-pickups-option__title subheading">
                    {intl.formatMessage({
                      id: `new_order.request_pickup_modal.every`
                    })}
                  </span>
                  <div className="br-request-pickup-modal__form__repeat-pickups__days">
                    {DAYS.map(({ value, label, labelAr }, index) => (
                      <Button
                        className={classNames({
                          'br-request-pickup-modal__form__repeat-pickups__days-selected':
                            repeatedPickup.days.includes(index),
                          'br-request-pickup-modal__form__repeat-pickups__days-one-selected':
                            repeatedPickup.days.includes(index) &&
                            repeatedPickup.days.length === 1
                        })}
                        key={value}
                        onClick={() => {
                          this.handleRepeatedPickupDayChange(index);
                        }}
                      >
                        {intl.locale === LOCALE.AR
                          ? labelAr
                          : label.substring(0, 3)}
                      </Button>
                    ))}
                  </div>
                </div>
                <div className="br-request-pickup-modal__form__repeat-pickups-options">
                  <span className="br-request-pickup-modal__form__repeat-pickups-option__title subheading">
                    {intl.formatMessage({
                      id: `new_order.request_pickup_modal.ends`
                    })}
                  </span>
                  <DatePicker
                    className="br-request-pickup-modal__form__date-picker"
                    dropdownClassName="br-request-pickup-modal__form__date-picker-dropdown"
                    placeholder={intl.formatMessage({
                      id: `new_order.request_pickup_modal.select`
                    })}
                    value={repeatedPickup.endDate}
                    onChange={this.handleOnChangeEndDate}
                    disabledDate={(current) =>
                      current < this.state.scheduledDate
                    }
                    getPopupContainer={(trigger) => trigger.parentElement}
                    showToday={false}
                    suffixIcon={<DatePickerIcon />}
                    allowClear={false}
                    format={(value) => {
                      const day = checkDate(value);
                      return `${day ? `${day},` : ''} ${dayjs(value).format(
                        day ? DATE_FORMAT : DATE_FORMAT_WITH_DAY
                      )}`;
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {(bulkyOptions?.heavyBulky || bulkyOptions?.lightBulky) && (
            <Form.Item
              name="isBulkyPickup"
              valuePropName="checked"
              className="br-form-without-label br-request-pickup-modal__form__bulky"
            >
              <Checkbox onChange={this.handleIsBulkyOnChange}>
                <div className="br-form__bulcky-checkbox-title-wrapper">
                  <div className="body-medium">
                    {intl.formatMessage({
                      id: `pickups.create_edit_pickup.form_labels.bulky_title`
                    })}
                  </div>
                  <span className="caption form__bulky-subtitle">
                    {intl.formatMessage({
                      id: `pickups.create_edit_pickup.form_labels.bulky_sub_title`
                    })}
                  </span>
                </div>
              </Checkbox>
            </Form.Item>
          )}

          {businessInfo?.subAccounts?.length && (
            <div className="br-form-row sub-buessniess">
              <Form.Item
                name="subAccountId"
                className="br-pickups__pickup-settings__form__notes-header"
                label={
                  <>
                    {intl.formatMessage({
                      id: 'order_types.sub_business_title'
                    })}
                    <span className="br-form-optional-label">
                      {intl.formatMessage({
                        id: 'form.optional_label'
                      })}
                    </span>
                  </>
                }
              >
                <Select
                  autoComplete={'' + Math.random()}
                  disabled={isLoading || pickupId}
                  placeholder={intl.formatMessage({
                    id: 'order_types.sub_business_placeholder'
                  })}
                >
                  {businessInfo?.subAccounts?.length &&
                    businessInfo?.subAccounts.map((account) => (
                      <Select.Option key={account?._id} value={account?._id}>
                        <div className="br-create-edit-pickup__location">
                          <div className="br-create-edit-pickup__location-item body">
                            <div>{account.name}</div>
                          </div>
                        </div>
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
          )}

          <div className="br-form-row__checkboxes-wrapper body">
            <Form.Item
              name="isFragileItem"
              valuePropName="checked"
              className="br-form-without-label  body"
            >
              <Checkbox>
                {intl.formatMessage(
                  {
                    id: `pickups.create_edit_pickup.form_labels.fragile_title`
                  },
                  {
                    span: (chunks) => (
                      <span className="body-medium">{chunks}</span>
                    )
                  }
                )}
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="isBigItem"
              valuePropName="checked"
              className="br-form-without-label  body"
            >
              <Checkbox disabled={isBulkyPickup}>
                {intl.formatMessage(
                  {
                    id: `pickups.create_edit_pickup.form_labels.big_title`
                  },
                  {
                    span: (chunks) => (
                      <span className="body-medium">{chunks}</span>
                    )
                  }
                )}
              </Checkbox>
            </Form.Item>
          </div>
          <BRButton
            type="link-color"
            label={intl.formatMessage({
              id: `new_order.request_pickup_modal.add_pickup_notes`
            })}
            prefixIcon={<PlusIcon />}
            className={classNames(
              'br-request-pickup-modal__form__add-notes-btn button-md',
              {
                'br-request-pickup-modal__form__add-notes-btn__hidden':
                  withNotes
              }
            )}
            onClick={() => this.setState({ withNotes: true })}
          />
          <div
            className={classNames(
              'br-request-pickup-modal__form__notes-container',
              {
                'br-request-pickup-modal__form__notes-container__visible':
                  withNotes
              }
            )}
          >
            <Form.Item
              className="br-pickups__pickup-settings__form__notes-header"
              label={
                <>
                  {intl.formatMessage({
                    id: `pickups.create_edit_pickup.form_labels.notes`
                  })}
                  <span className="br-form-optional-label">
                    {intl.formatMessage({
                      id: 'form.optional_label'
                    })}
                  </span>
                </>
              }
              name="notes"
            >
              <Input.TextArea
                className="br-pickups__pickup-settings__form__notes-field"
                placeholder={intl.formatMessage({
                  id: `new_order.request_pickup_modal.notes_placeholder`
                })}
                autoSize={{ minRows: 3, maxRows: 3 }}
              />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default injectIntl(withRouter(CreateEditPickupModal));
